import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { setPersistence, browserSessionPersistence, User } from "firebase/auth";
import { useFirebase } from "./FirebaseContext";
import { DataApiClient } from "../api/dataApiClient";
import { AdminApiClient } from "../api/adminApiClient";
import { LOGIN_TIMESTAMP_KEY } from "../pages/Login/Login";
import { useToast } from "../hooks/useToast";

export interface JWTUser {
  cherryPayRole?: string;
  adminRole?: string;
  businessId?: string;
}

const isValidJWTUser = (decodedJwt: {
  [key: string]: any;
}): decodedJwt is JWTUser => {
  const hasCherryPayRole =
    "cherryPayRole" in decodedJwt &&
    typeof decodedJwt["cherryPayRole"] === "string";

  const hasBusinessId =
    "businessId" in decodedJwt && typeof decodedJwt["businessId"] === "string";

  const hasHubAdminRole =
    "adminRole" in decodedJwt && typeof decodedJwt["adminRole"] === "string";

  return hasHubAdminRole || (hasCherryPayRole && hasBusinessId);
};

const decodeJwt = (accessToken: string) => {
  try {
    return JSON.parse(window.atob(accessToken.split(".")[1]));
  } catch (e) {
    return {};
  }
};

export interface UserContext {
  /** The current logged in user */
  user: User | null;

  dataApiClient: DataApiClient | null;
  adminApiClient: AdminApiClient | null;

  /** Log out of the current session */
  logout: () => Promise<void>;
  /** Is the user context still initialising? */
  isLoading: boolean;
  isSuperAdmin: boolean;
}

export const UserContext = createContext<UserContext | null>(null);

interface UserContextProviderProps {
  children: React.ReactNode;
}

const useDecodedJwt = (user: User | null) => {
  return useMemo<JWTUser | null>(() => {
    if (!user) {
      return null;
    }
    const decoded = decodeJwt(user.stsTokenManager.accessToken);

    if (isValidJWTUser(decoded)) {
      return decoded as JWTUser;
    } else {
      return null;
    }
  }, [user]);
};

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { auth } = useFirebase();
  const { showErrorToast } = useToast();
  const [storedContextBusinessId, setStoredContextBusinessId] = useState(() => {
    return localStorage.getItem("contextBusinessId");
  });
  const checkTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const lastFocusTime = useRef<number>(0);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setUser(user);
      await setPersistence(auth, browserSessionPersistence);
      setIsLoading(false);
    });
  }, [auth, setUser, setIsLoading]);

  const dataApiClient = useMemo(
    () => (user ? new DataApiClient(user) : null),
    [user]
  );

  const platformApiClient = useMemo(
    () => (user ? new AdminApiClient(user) : null),
    [user, storedContextBusinessId]
  );

  // Decode the JWT so that we can access the user claims and determine their role
  const userJwt = useDecodedJwt(user);

  const isSuperAdmin = userJwt?.adminRole === "SuperAdmin";

  if (userJwt && !isSuperAdmin) {
    auth.signOut();
  }

  const ctx = useMemo<UserContext>(
    () => ({
      user,
      dataApiClient: dataApiClient,
      adminApiClient: platformApiClient,
      isLoading: isLoading,
      logout: async () => {
        await auth.signOut();
        localStorage.removeItem(LOGIN_TIMESTAMP_KEY);
      },
      userBusinessId: userJwt?.businessId ?? null,
      isSuperAdmin: userJwt?.adminRole === "SuperAdmin",
    }),
    [isLoading, auth, dataApiClient, platformApiClient, user, userJwt, setUser]
  );

  const stopSessionChecker = () => {
    if (checkTimeout.current !== null) {
      clearTimeout(checkTimeout.current);
    }
  };

  const checkSessionValidity = useCallback(() => {
    stopSessionChecker();

    checkTimeout.current = setTimeout(() => {
      if (ctx.user) {
        const currTs = new Date().getTime();
        // check for 15 min of inactivity
        if (!lastFocusTime.current) {
          lastFocusTime.current = currTs;
        } else {
          const min15 = 15 * 60 * 1000;

          if (currTs > lastFocusTime.current + min15) {
            ctx.logout();
            showErrorToast(
              "Session expired due to inactivity. Please login again."
            );
            lastFocusTime.current = 0;
          } else {
            if (window.document.hasFocus()) {
              lastFocusTime.current = currTs;
            }
          }
        }

        // check for 24 hrs of total session time
        const loginTsStr = localStorage.getItem(LOGIN_TIMESTAMP_KEY);
        const loginTs = parseInt(loginTsStr ?? "0");
        const hrs24 = 24 * 60 * 60 * 1000;
        if (currTs > loginTs + hrs24) {
          ctx.logout();
          showErrorToast("Session expired. Please login again.");
        }
      }
      checkSessionValidity();
    }, 5000);
  }, [ctx]);

  useEffect(() => {
    checkSessionValidity();
  }, [ctx]);

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContext => {
  const ctx = useContext(UserContext);
  if (!ctx) {
    throw new Error("Missing user context");
  }

  return ctx;
};
